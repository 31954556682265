import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import logoAtpco from "../images/references/ATPCO_partner.png"
import logoFrench from "../images/Logo_French_Tech.png"
import logoRegion from "../images/Logo-region.png"
import logoUnitec from "../images/unitec.png"
import logoOM from "../images/references/OM.png"
import teams from "../images/team.png"
import unitec from "../images/locaux.png"




const UsPage = () => (
  <Layout navbarScrollOpacity>
    <SEO title="newpricer story team contact" />

    <div className="bg-light photo">
      <div className="container">
        <h3 className="h1 text-dark py-3">
            our story
        </h3>
      </div>
      <div className="container my-3 d-flex justify-content-center align-items-center">
        <div className="row">
          <div className="col h4 font-weight-light text-secondary text-justify ons mx-5">
            <h1 className="h1 font-weight-lighter text-center text-dark p-3 m-3">
              by pricers for pricers
            </h1>
            <p className="mb-5">
              Newpricer has been founded by a team of former Pricing and Inventory managers from major airlines.
            </p>
            <p className="mb-5">
             in charge of pricing, ticketing and commercial departments like you, we developed prototypes and algorithms to solve our own business issues.
            </p>
            <p className="mb-5">
             Then we founded Newpricer to accelerate innovation and spread our solutions.
            </p>
          </div>
          <div className="col d-flex justify-content-center align-items-center">
            <img src={unitec} alt="Headquarters Bordeaux Unitec" className="us"></img>
          </div>
        </div>
      </div>
    </div>


    <div className="foot photo p-3">
      <div className="container p-3">
        <h3 className="h1 text-light py-3">
            our team
        </h3>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col d-flex justify-content-center align-items-center">
            <img src={teams} alt="some of us" className="us"></img>
          </div>
          <div className="col h4 font-weight-light text-light text-justify ons mx-5">
            <h1 className="h1 font-weight-lighter text-center text-light p-3 m-3">
              motivated by your challenges
            </h1>
            <p className="mb-5 px-5">
              newpricer's team is composed of talented experts: revenue managers from various industries,
              marketing specialists and data researchers.
            </p>
            <p className="mb-5 px-5">
              we know that pricing strategy is a complex balance
              of market trends and commercial objectives. We developed a
              customized approach coupling sense of business and data analysis.
            </p>
            <p className="mb-5 px-5">
              we provide relevant business decisions to exceed strategic goals.
            </p>
          </div>
        </div>
      </div>
    </div>


    <div className="p-4">
      <div className="container">
        <h3 className="h1 text-dark py-3">
            innovation partners
        </h3>
      </div>
      <div className="container">
        <div className="row my-5 text-center">
          <div className="col">
            <img class="logo2" src={logoFrench} alt="FrenchTech"></img>
          </div>
          <div className="col ">
            <img class="logo2" src={logoUnitec} alt="Unitec"></img>
          </div>
          <div className="col ">
            <img class="logo" src={logoRegion} alt="NouvelleAquitaine"></img>
          </div>
        </div>
        <div className="row my-5 text-center">
          <div className="col ">
            <img class="logo" src={logoAtpco} alt="ATPCO"></img>
          </div>
           <div className="col">
            <img class="logo1" src={logoOM} alt="OM"></img>
          </div>
        </div>
      </div>
    </div>

    <div className="h-150vh bg-danger p-4 photo">
      <div className="container">
        <h3 className="h1 text-light py-3">
            to contact us
        </h3>
      </div>
        <div className="row">
          <div className="col us d-flex text-center justify-content-center align-items-center">
            <i class="col h1 fas fa-id-card text-light"></i>
          </div>
          <div className="col h4 font-weight-light text-light text-justify ons mx-5">
            <h1 className="h1 font-weight-lighter text-light ">
              newpricer
            </h1>
            <p className="">
              3 rue Ravez
            </p>
            <p className="">
              33 000 Bordeaux
            </p>
            <p className="">
              France
            </p>
            <p className="">
             contact@newpricer.com
            </p>
          </div>
          <div className="col d-flex text-center justify-content-center align-items-center us">
            <a href="mailto:newpricer <contact@newpricer.com>?subject=New Pricer Demo" className="btn btn-outline-light px-3">
              <p class="h4 font-weight-lighter">join us</p>
            </a>
          </div>
        </div>

    </div>


  </Layout>
)

export default UsPage
